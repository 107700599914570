<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style>
#mainHome {
  min-height: 100vh;
}
.fullH {
  min-height: 100vh;
}
.gradient {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    #151515e7
  );
  width: 100%;
  height: calc(100vh - 80px);
  min-height: 100vh;
  object-fit: cover;
  position: relative;
  z-index: 2;
}
video {
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  min-height: 100%;
  object-fit: cover;
}
</style>

<template>
  <div class="container-fluid p-0">
    <div class="container2">
      <!-- <h1 class="title">Responsive Slider Timeline</h1> -->
      <div class="timeline">
        <div ref="swiperRef" class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              style="background-image: url(https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/timeline%2F2018_noviembre.jpg?alt=media&token=98e386e6-dd1d-4e54-952b-7720c68aad02)"
              data-year="2011"
            >
              <div class="swiper-slide-content">
                <span class="timeline-year">2018</span>
                <h4 class="timeline-title">Primera alianza</h4>
                <p class="timeline-text">
                  Noviembre: Grupo Ya Quedó y Blue Ocean Global Technology
                  establecen una alianza
                </p>
              </div>
            </div>
            <div
              class="swiper-slide"
              style="background-image: url(https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/timeline%2F2019_abril.jpg?alt=media&token=9f26d8e2-1e9a-4b97-a2ca-a89924fe3df6)"
              data-year="2012"
            >
              <div class="swiper-slide-content">
                <span class="timeline-year">2019</span>
                <h4 class="timeline-title">Imagen</h4>
                <p class="timeline-text">
                  Abril: Girl Power Talk termina el desarrollo de su imagen y
                  logotipo.
                </p>
              </div>
            </div>
            <div
              class="swiper-slide"
              style="background-image: url(https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/timeline%2F2019_dicimebre.jpg?alt=media&token=c75ffd46-fbcf-45c7-9869-987bce57b709)"
              data-year="2013"
            >
              <div class="swiper-slide-content">
                <span class="timeline-year">2019</span>
                <h4 class="timeline-title">Incorporación formal</h4>
                <p class="timeline-text">
                  Diciembre: Girl Power Talk se incorpora como empresa en India.
                </p>
              </div>
            </div>
            <div
              class="swiper-slide"
              style="background-image: url(https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/timeline%2F2020_julio.jpg?alt=media&token=2e9f3705-1081-4656-9c69-a347eda5ce73)"
              data-year="2014"
            >
              <div class="swiper-slide-content">
                <span class="timeline-year">2020</span>
                <h4 class="timeline-title">
                  México e India juntos
                </h4>
                <p class="timeline-text">
                  Julio: Girl Power Talk y Grupo Ya Quedó establecen una alianza
                  formal para la formación de Girl Power Talk México.
                </p>
              </div>
            </div>
            <div
              class="swiper-slide"
              style="background-image: url(https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/timeline%2F2020_agosto.jpg?alt=media&token=4bc9f166-d64a-40dd-8d1f-f7db6c67b3c2)"
              data-year="2015"
            >
              <div class="swiper-slide-content">
                <span class="timeline-year">2020</span>
                <h4 class="timeline-title">Éxito en India.</h4>
                <p class="timeline-text">
                  Agosto: Girl Power Talk recibe el 'Indian Achievers Award' por
                  “ser un gran logro profesional e impulsar las contribuciones
                  sociales”.
                </p>
              </div>
            </div>
            <div
              class="swiper-slide"
              style="background-image: url(https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/timeline%2F2021_febrero.jpg?alt=media&token=35e9151f-32bd-451f-b650-5fa9004be8f3)"
              data-year="2016"
            >
              <div class="swiper-slide-content">
                <span class="timeline-year">2021</span>
                <h4 class="timeline-title">Girl Power Talk México</h4>
                <p class="timeline-text">
                  Febrero: Girl Power Talk México lanza su sitio web e inicia
                  operaciones.
                </p>
              </div>
            </div>
          </div>
          <div
            class="swiper-button-prev"
            style="color: #d4a024 !important"
          ></div>
          <div
            class="swiper-button-next"
            style="color: #d4a024 !important"
          ></div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "timeline",
  data() {
    return {
      swiperInit: null,
      swiperInstance: null,
      years: [
        {
          year: 2018,
          title: "Noviembre - Primera alianza",
          body:
            "Grupo Ya Quedó y Blue Ocean Global Technology establecen una alianza.",
          img: "url(https://unsplash.it/1920/500?image=11)",
        },
        {
          year: 2019,
          title: "Imagen",
          body:
            "Girl Power Talk termina el desarrollo de su imagen y logotipo.",
          img: "url(https://unsplash.it/1920/500?image=12)",
        },
        {
          year: 2019,
          title: ">Our nice super title",
          body:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto facilis praesentium velit eaque, maxime consectetur expedita? Natus earum rem hic repudiandae sit aliquam nostrum rerum in minus. Incidunt, ipsam doloribus?",
          img: "url(https://unsplash.it/1920/500?image=13)",
        },
        {
          year: 2013,
          title: ">Our nice super title",
          body:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Iusto facilis praesentium velit eaque, maxime consectetur expedita? Natus earum rem hic repudiandae sit aliquam nostrum rerum in minus. Incidunt, ipsam doloribus?",
          img: "url(https://unsplash.it/1920/500?image=14)",
        },
      ],
    };
  },
  mounted() {
    this.swiperInit = new Swiper(".timeline .swiper-container", {
      speed: 1600,
      direction: "vertical",
      spaceBetween: 0,
      pagination: ".swiper-pagination",
      // paginationBulletRender: function(swiper, index, className) {
      //   let year = ["2015", "2016", "2017", "2018", "2019", "2020"];
      //   return (
      //     '<span class="' +
      //     className +
      //     '" style="color:#d4a024 !important" >' +
      //     year[index] +
      //     "</span>"
      //   );
      // },
      paginationClickable: true,
      nextButton: ".swiper-button-next",
      prevButton: ".swiper-button-prev",
      breakpoints: {
        768: {
          direction: "horizontal",
        },
      },
      //   renderBullet:
    });
    this.swiperInstance = this.$refs.swiperRef.swiper;
  },
};
</script>

<style scoped>
@import "https://unpkg.com/swiper/swiper-bundle.min.css";

html,
body,
.container2 {
  height: 100%;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}

.container2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex-direction: column;
}

.title {
  font-size: 38px;
  color: #616161;
  font-style: italic;
  font-weight: 800;
}

.timeline {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 5px 25px 5px rgba(0, 0, 0, 0.2);
}
.timeline .swiper-container {
  height: 600px;
  width: 100%;
  position: relative;
}
.timeline .swiper-wrapper {
  transition: 2s cubic-bezier(0.68, -0.4, 0.27, 1.34) 0.2s;
}
.timeline .swiper-slide {
  position: relative;
  color: #fff;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.timeline .swiper-slide::after {
  content: "";
  position: absolute;
  z-index: 1;
  right: -115%;
  bottom: -10%;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  box-shadow: -230px 0 150px 60vw rgba(0, 0, 0, 0.7);
  border-radius: 100%;
}
.timeline .swiper-slide-content {
  position: absolute;
  text-align: center;
  width: 80%;
  max-width: 310px;
  right: 50%;
  top: 13%;
  transform: translate(50%, 0);
  font-size: 12px;
  z-index: 2;
}
.timeline .swiper-slide .timeline-year {
  display: block;
  font-style: italic;
  font-size: 42px;
  margin-bottom: 50px;
  transform: translate3d(20px, 0, 0);
  color: #d4a024 !important;
  font-weight: 300;
  opacity: 0;
  transition: 0.2s ease 0.4s;
}
.timeline .swiper-slide .timeline-title {
  font-weight: 800;
  font-size: 34px;
  margin: 0 0 30px;
  opacity: 0;
  transform: translate3d(20px, 0, 0);
  transition: 0.2s ease 0.5s;
}
.timeline .swiper-slide .timeline-text {
  line-height: 1.5;
  opacity: 0;
  transform: translate3d(20px, 0, 0);
  transition: 0.2s ease 0.6s;
}
.timeline .swiper-slide-active .timeline-year {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: 0.4s ease 1.6s;
}
.timeline .swiper-slide-active .timeline-title {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: 0.4s ease 1.7s;
}
.timeline .swiper-slide-active .timeline-text {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: 0.4s ease 1.8s;
}
.timeline .swiper-pagination {
  right: 15% !important;
  height: 100%;
  display: none;
  flex-direction: column;
  justify-content: center;
  font-style: italic;
  font-weight: 300;
  font-size: 18px;
  z-index: 1;
}
.timeline .swiper-pagination::before {
  content: "";
  position: absolute;
  left: -30px;
  top: 0;
  height: 100%;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.2);
}
.timeline .swiper-pagination-bullet {
  width: auto;
  height: auto;
  text-align: center;
  opacity: 1;
  background: transparent;
  color: #d4a024 !important;
  margin: 15px 0 !important;
  position: relative;
}
.timeline .swiper-pagination-bullet::before {
  content: "<-----";
  position: absolute;
  top: 8px;
  left: -32.5px;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: #d4a024 !important;
  transform: scale(0);
  transition: 0.2s;
}
.timeline .swiper-pagination-bullet-active {
  color: #d4a024;
}
.timeline .swiper-pagination-bullet-active::before {
  transform: scale(1);
}
.timeline .swiper-button-next,
.timeline .swiper-button-prev {
  background-size: 20px 20px;
  top: 15%;
  width: 20px;
  height: 20px;
  margin-top: 0;
  z-index: 2;
  transition: 0.2s;
}
.timeline .swiper-button-prev {
  left: 8%;
  /* background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23d4a024'%2F%3E%3C%2Fsvg%3E"); */
}
.timeline .swiper-button-prev:hover {
  transform: translateX(-3px);
}
.timeline .swiper-button-next {
  right: 8%;
  /* background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23d4a024'%2F%3E%3C%2Fsvg%3E"); */
}
.timeline .swiper-button-next:hover {
  transform: translateX(3px);
}
@media screen and (min-width: 768px) {
  .timeline .swiper-slide::after {
    right: -30%;
    bottom: -8%;
    width: 240px;
    height: 50%;
    box-shadow: -230px 0 150px 50vw rgba(0, 0, 0, 0.7);
  }
  .timeline .swiper-slide-content {
    right: 30%;
    top: 50%;
    transform: translateY(-50%);
    width: 310px;
    font-size: 11px;
    text-align: right;
  }
  .timeline .swiper-slide .timeline-year {
    margin-bottom: 0;
    font-size: 32px;
  }
  .timeline .swiper-slide .timeline-title {
    font-size: 46px;
    margin: 0;
  }
  .timeline .swiper-pagination {
    display: flex;
  }
  .timeline .swiper-button-prev {
    top: 15%;
    left: auto;
    right: 15%;
    transform: rotate(90deg) translate(0, 10px);
  }
  .timeline .swiper-button-prev:hover {
    transform: rotate(90deg) translate(-3px, 10px);
  }
  .timeline .swiper-button-next {
    top: auto;
    bottom: 15%;
    right: 15%;
    transform: rotate(90deg) translate(0, 10px);
  }
  .timeline .swiper-button-next:hover {
    transform: rotate(90deg) translate(3px, 10px);
  }
}
@media screen and (min-width: 1024px) {
  .timeline .swiper-slide::after {
    right: -20%;
    bottom: -12%;
    width: 240px;
    height: 50%;
    box-shadow: -230px 0 150px 39vw rgba(0, 0, 0, 0.7);
  }
  .timeline .swiper-slide-content {
    right: 25%;
  }
}
</style>

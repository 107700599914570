<template>
  <b-container fluid>
    <b-row style="height:18em" class="d-flex d-sm-none mobile-custom-footer">
      <b-col cols="12" class="d-flex flex-column justify-content-around">
        <div>
          <ul
            style="margin: 0"
            class="d-flex flex-row justify-content-around  pr-5"
          >
            <li class="nav-link ">
              <b-icon
                class="icons-style"
                icon="instagram"
                variant="danger"
              ></b-icon>
            </li>
            <li class="nav-link">
              <b-icon
                class="icons-style"
                icon="facebook"
                variant="danger"
              ></b-icon>
            </li>
            <li class="nav-link">
              <b-icon
                class="icons-style"
                icon="twitter"
                variant="danger"
              ></b-icon>
            </li>
            <li class="nav-link">
              <b-icon
                class="icons-style"
                icon="linkedin"
                variant="danger"
              ></b-icon>
            </li>
          </ul>
        </div>
        <div class="container-fluid d-flex flex-row ">
          <div class="col-6">
            <ul class="text-white">
              <li>
                <p
                  @click="$router.push('Apply')"
                  class="font-weight-light terms-font-size"
                >
                  APLICA AHORA
                </p>
              </li>
              <li>
                <p class="font-weight-light terms-font-size">
                  <a href="https://www.yaquedo.com.mx/aviso_privacidad.html"
                    >AVISO DE PRIVACIDAD</a
                  >
                </p>
              </li>
              <!-- <li>
                <p class="font-weight-light terms-font-size">
                  TERMINOS & CONDICIONES
                </p>
              </li> -->
            </ul>
          </div>
          <div class="col-6  d-flex flex-column justify-content-center">
            <img style="height:1.2em" src="/Media/Icons/gpt-logo.png" alt="" />
          </div>
        </div>
        <div class="my-3">
          <p
            style="margin: 0;font-size: x-small"
            class="d-block d-sm-none  font-weight-light pl-4 text-white"
          >
            GIRL <span class="font-weight-bold text-danger">POWER</span> TALK
            <span class="font-weight-bold text-danger">MÉXICO</span> 2021&copy;
            TODOS LOS DERECHOS RESERVADOS
          </p>
        </div>
      </b-col>
    </b-row>
    <b-row style="height:16em" class="d-none d-sm-flex">
      <b-col cols="7" class=" d-flex flex-column justify-content-center">
        <ul>
          <li>
            <p class="h5 font-weight-light">
              GIRL <span class="font-weight-bold">POWER</span> TALK
              <span class=" mx-2 text-danger font-weight-bold">MÉXICO</span>
            </p>
          </li>
          <li>
            <p>
              <span class="text-danger">Girl Power Talk </span> en colaboración
              con <br />
              <span
                ><a
                  href="https://www.yaquedo.com.mx/"
                  style="text-decoration: none; color: #ff8c1a"
                  ><span class="font-weight-bold ">Grupo Ya Quedó</span></a
                ></span
              >
            </p>
          </li>
        </ul>
      </b-col>
      <b-col cols="5" class="d-flex flex-row">
        <b-col cols="6" class="d-flex flex-column justify-content-center">
          <ul>
            <li v-show="false">
              <p class="font-weight-bold">
                (+52) 55 56575859/
              </p>
            </li>
            <li>
              <p class="font-weight-light">
                contacto@<span class="text-danger">gptmx</span>.com
              </p>
            </li>
          </ul>
        </b-col>
        <b-col cols="6" class="d-flex flex-column justify-content-center ">
          <ul>
            <li>
              <p
                @click="$router.push('Apply')"
                class="font-weight-bold terms-font-size"
              >
                APLICA AHORA
              </p>
            </li>
            <li>
              <p class="font-weight-bold terms-font-size ">
                <a
                  style="text-decoration: none"
                  class="text-dark"
                  href="https://www.yaquedo.com.mx/aviso_privacidad.html"
                  >AVISO DE PRIVACIDAD</a
                >
              </p>
            </li>
            <!-- <li>
              <p class="font-weight-bold terms-font-size">
                TERMINOS & CONDICIONES
              </p>
            </li> -->
          </ul>
        </b-col>
      </b-col>
    </b-row>
    <b-row
      class="d-none d-sm-flex flex-column justify-content-center footer-desktop-height"
    >
      <b-col cols="12" sm="9" class="d-flex flex-column justify-content-center">
        <p
          style="margin: 0"
          class="d-none d-sm-block font-weight-light pl-4 text-white"
        >
          GIRL <span class="font-weight-bold text-danger">POWER</span> TALK
          <span class="font-weight-bold text-danger">MÉXICO</span>
          <small>2021&copy; TODOS LOS DERECHOS RESERVADOS</small>
        </p>
      </b-col>
      <b-col
        cols="12"
        sm="3"
        class="d-none d-sm-flex flex-column justify-content-center"
      >
        <ul
          style="margin: 0"
          class="d-flex flex-row justify-content-around  pr-5"
        >
          <li class="nav-link ">
            <b-icon
              class="icons-style"
              icon="instagram"
              variant="danger"
            ></b-icon>
          </li>
          <li class="nav-link">
            <b-icon
              class="icons-style"
              icon="facebook"
              variant="danger"
            ></b-icon>
          </li>
          <li class="nav-link">
            <b-icon
              class="icons-style"
              icon="twitter"
              variant="danger"
            ></b-icon>
          </li>
          <li class="nav-link">
            <b-icon
              class="icons-style"
              icon="linkedin"
              variant="danger"
            ></b-icon>
          </li>
        </ul>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "footercomponent",
};
</script>

<style scoped>
ul {
  list-style: none;
}
* {
  font-size: 1em;
}
li:hover {
  cursor: pointer;
}
.icons-style {
  min-height: 0.9em;
  height: 0.9em;
}
.icons-style:hover {
  transition: color 0.3s ease-in-out;
  color: #f7f7f7 !important;
}
.footer-desktop-height {
  height: 3em !important;
  background-color: #151515;
}
.terms-font-size {
  font-size: x-small;
}
.mobile-custom-footer {
  background-color: #151515;
}
</style>

<template>
  <b-container id="mainHome" fluid>
    <!-- SECTION two separators -->
    <b-row>
      <b-col sm="2">
        <sidebarcontent />
      </b-col>
      <b-col sm="10">
        <!-- SECTION LANDING SECTION MOBILE -->
        <b-row v-if="isMobile">
          <b-col cols="12" class="m-0 p-0 ">
            <!-- NOTE Landing-video -->
            <div class="wrapper">
              <div class="gradient"></div>
              <video ref="landingVideo" loop muted>
                <source
                  src="https://firebasestorage.googleapis.com/v0/b/drntss.appspot.com/o/landing.mp4?alt=media&token=bfeb5526-76ea-455c-a056-e12b41f83b43"
                  type="video/mp4"
                />
              </video>
            </div>
            <div
              style="z-index: 99"
              class="col-12 d-flex flex-column justify-content-between p-0 m-0"
            >
              <b-container fluid class="p-0 m-0">
                <b-row class="p-0 m-0 fullH">
                  <b-col class="d-flex flex-column  " cols="12">
                    <b-row class="py-3">
                      <b-col
                        cols="6"
                        class="d-flex flex-column justify-content-center"
                      >
                        <b-icon
                          variant="light"
                          class="h1"
                          style="margin:0"
                          v-b-toggle.sidebar-backdrop
                          icon="list"
                        ></b-icon>
                      </b-col>
                      <b-col cols="6">
                        <b-button
                          @click="$router.push('Apply')"
                          size="lg"
                          variant="danger"
                          >Aplica ahora</b-button
                        >
                      </b-col>
                    </b-row>
                    <b-row style="height:100%">
                      <b-col class="d-flex flex-column justify-content-center">
                        <b-row>
                          <b-col cols="12">
                            <h1 class="text-white text-uppercase mx-2">
                              <b>Girl Power Talk </b>
                              <span class="text-danger">México</span>
                            </h1>
                            <p
                              class="h5 font-weight-light text-white mx-2 pt-2"
                            >
                              Empoderando a las mujeres y jóvenes de México
                            </p>
                          </b-col>

                          <b-col
                            cols="6"
                            class="d-flex flex-column justify-content-center mt-5"
                          >
                            <p
                              v-show="!playingVideo"
                              @click="playPauseVideo"
                              class="h1 mb-2  "
                            >
                              <b-icon variant="light" icon="play-fill"></b-icon>
                            </p>
                            <p
                              v-show="playingVideo"
                              @click="playPauseVideo"
                              class="h1 mb-2  "
                            >
                              <b-icon
                                variant="light"
                                icon="pause-fill"
                              ></b-icon>
                            </p>
                          </b-col>
                          <b-col>
                            <!-- <sidebarcontent /> -->
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-container>
            </div>
            <!-- </div> -->
          </b-col>
        </b-row>
        <!-- SECTION LANDING SECTION DESKTOP -->
        <b-row v-if="!isMobile">
          <b-col cols="12" class="m-0 p-0 ">
            <!-- <div class=" video-container m-0 "> -->
            <!-- NOTE Landing-video -->
            <div class="wrapper">
              <div class="gradient"></div>
              <video ref="landingVideo" loop muted>
                <source
                  src="https://firebasestorage.googleapis.com/v0/b/drntss.appspot.com/o/landing.mp4?alt=media&token=bfeb5526-76ea-455c-a056-e12b41f83b43"
                  type="video/mp4"
                />
              </video>
            </div>
            <div
              style="z-index: 99"
              class="col-12 d-flex flex-column justify-content-between p-0 m-0"
            >
              <b-container fluid class="p-0 m-0">
                <b-row class="p-0 m-0 fullH">
                  <b-col cols="3">
                    <!-- <sidebarcontent /> -->
                  </b-col>
                  <b-col
                    class="d-flex flex-column justify-content-between "
                    cols="9"
                  >
                    <div class="d-flex flex-row justify-content-end mt-4">
                      <div class="d-none d-sm-block btn btn-danger mr-5">
                        <router-link to="/apply" class="text-white">
                          Únete al equipo
                        </router-link>
                      </div>
                    </div>
                    <div class="mb-0 ">
                      <b-container>
                        <b-row>
                          <b-col cols="6">
                            <h1 class="text-white text-uppercase ml-5">
                              <b>Girl Power Talk </b>
                              <span class="text-danger">Mexico</span>
                            </h1>
                            <p
                              class="ml-5 h5 font-weight-light text-white mb-5"
                            >
                              Empoderando a las mujeres y jóvenes de México
                            </p>
                          </b-col>
                          <b-col
                            cols="6"
                            class="d-flex flex-column justify-content-center"
                          >
                            <p
                              v-show="!playingVideo"
                              @click="playPauseVideo"
                              class="h1 mb-2  "
                            >
                              <b-icon variant="light" icon="play-fill"></b-icon>
                            </p>
                            <p
                              v-show="playingVideo"
                              @click="playPauseVideo"
                              class="h1 mb-2  "
                            >
                              <b-icon
                                variant="light"
                                icon="pause-fill"
                              ></b-icon>
                            </p>
                          </b-col>
                        </b-row>
                      </b-container>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </div>
            <!-- </div> -->
          </b-col>
        </b-row>
        <!-- SECTION Women-1 -->
        <b-row class="bg-dark">
          <b-col id="women-section-1" cols="12" class="women-section-1 mt-2">
            <b-container fluid class="h-100">
              <b-row class="h-100">
                <b-col
                  v-if="isMobile"
                  class="pt-3 d-flex flex-row justify-content-center align-self-center"
                >
                  <p class="h5 text-weight-light text-light">
                    Algunas grandes mujeres
                  </p>
                </b-col>
                <b-col
                  :col="true"
                  class="d-flex flex-column justify-content-center align-content-center "
                >
                  <b-carousel
                    id="carousel-fade"
                    style="height:30em; width: 25em"
                    fade
                    controls
                    indicators
                    :interval="4000"
                    class="align-self-center"
                    ref="womenCarouselref"
                    @sliding-start="controlWomenCarousel1"
                  >
                    <b-carousel-slide
                      v-for="item in women"
                      :key="item.title"
                      style="height:30em; width: 25em"
                    >
                      <b-card
                        overlay
                        class="card-img-style"
                        :style="item.img"
                        img-alt="Card Image"
                        text-variant="light"
                      >
                        <b-card-text
                          class="text-light h-100 d-flex flex-column justify-content-end"
                        >
                          <div
                            v-if="isMobile"
                            class="d-flex flex-column text-center"
                          >
                            <p class="text-white text-weight-light">
                              <i> {{ item.quote }}</i>
                            </p>
                          </div>
                          <div class="d-flex flex-column text-center">
                            <p class=" text-white h4 my-3 text-weight-light">
                              {{ item.title }}
                            </p>
                          </div>
                        </b-card-text>
                      </b-card>
                    </b-carousel-slide>
                  </b-carousel>
                </b-col>
                <b-col
                  v-if="!isMobile"
                  cols="7"
                  class="text-white d-flex flex-column justify-content-center"
                >
                  <div>
                    <p class="h1 font-weight-light mb-4">
                      Mujeres exepcionales de México
                    </p>
                  </div>
                  <div class=" mx-5 text-center  ">
                    <blockquote class="blockquote">
                      <p class="mb-3 font-weight-light">
                        <i>
                          {{ women[carouselWomenIndex1].quote }}
                        </i>
                      </p>
                      <footer class="blockquote-footer text-white">
                        <cite title="Source Title">{{
                          women[carouselWomenIndex1].years
                        }}</cite>
                      </footer>
                    </blockquote>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
        <!-- SECTION TIMELINE -->
        <!-- FIXME El carousel desenfoca los slides -->
        <b-row>
          <b-col class="h-100 p-0 m-0">
            <timeline></timeline>
          </b-col>
        </b-row>
        <!-- SECTION Women-2 -->
        <b-row>
          <b-col
            id="women-section-1"
            cols="12"
            class="women-section-1 "
            style="background-image: linear-gradient( to bottom, #15151583,#15151583), url('Media/images/section-backgrounds/bg-women3.jpg')"
          >
            <b-container fluid class="h-100">
              <b-row class="h-100">
                <b-col
                  v-if="!isMobile"
                  cols="7"
                  class="d-flex flex-column justify-content-center"
                >
                  <div class=" mx-5 text-center text-white ">
                    <p class=" h1 font-weight-bold my-4">
                      Mujeres exepcionales de México
                    </p>
                    <blockquote class="blockquote">
                      <p class="mb-3 font-weight-light">
                        {{ women2[carouselWomenIndex2].quote }}
                      </p>
                      <footer class="blockquote-footer text-white">
                        <cite title="Source Title">{{
                          women2[carouselWomenIndex2].years
                        }}</cite>
                      </footer>
                    </blockquote>
                  </div>
                </b-col>
                <b-col
                  :col="true"
                  class="d-flex flex-column justify-content-center align-content-center"
                >
                  <b-carousel
                    id="carousel-fade"
                    style="height:30em; width: 25em"
                    fade
                    controls
                    indicators
                    :interval="4000"
                    class="align-self-center"
                    ref="womenCarouselref"
                    @sliding-start="controlWomenCarousel2"
                  >
                    <b-carousel-slide
                      v-for="item in women2"
                      :key="item.title"
                      style="height:30em; width: 25em"
                    >
                      <b-card
                        overlay
                        class="card-img-style"
                        :style="item.img"
                        img-alt="Card Image"
                        text-variant="white"
                      >
                        <b-card-text
                          class=" h-100 d-flex flex-column justify-content-end"
                        >
                          <div
                            v-if="isMobile"
                            class="d-flex flex-column text-center"
                          >
                            <p class=" text-white text-weight-light">
                              <i> {{ item.quote }}</i>
                            </p>
                          </div>
                          <div class="d-flex flex-column text-center">
                            <p class=" text-white h4 my-3 text-weight-light">
                              {{ item.title }}
                            </p>
                          </div>
                        </b-card-text>
                      </b-card>
                    </b-carousel-slide>
                  </b-carousel>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
        <!-- SECTION GET IN TOUCH  -->
        <b-row class="get-in-touch-bg">
          <b-col
            style="height:14em"
            class="d-flex flex-column justify-content-center "
          >
            <div class="container">
              <div class="row my-3">
                <div class="col-12">
                  <p class="h3" :class="{ h5: isMobile }">
                    Si tienes una historia inspiradora, contáctanos.
                    #GirlPowerTalkMexico #GPTMexico
                  </p>
                </div>
              </div>
              <div class="row my-3">
                <div class="col-12">
                  <div class="btn btn-outline-danger">
                    <a href="#contacto" class="text-danger custom-link"
                      >Contáctanos</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
        <!-- SECTION ARTICLES  -->
        <!-- FIXME  1. El pagination-bullet muestra más bullets de los que se utilizan -->
        <!-- FIXME  1. En mobile debo centrar los slides -->
        <b-row v-if="false" style="height: auto">
          <b-col class=" pb-5">
            <div ref="articlesRef" class="swiper-container ">
              <div class="swiper-wrapper">
                <div
                  v-for="article in articles"
                  :key="article.article_id"
                  class="swiper-slide ml-md-5 pl-md-5 pb-md-5"
                  style="max-width: 600px;"
                >
                  <div class="container ">
                    <b-card
                      bg-variant="light"
                      no-body
                      class="overflow-hidden border-1"
                      style="max-width: 540px;"
                      footer-tag="footer"
                    >
                      <b-row no-gutters>
                        <b-col md="6">
                          <b-card-img
                            :src="article.cover_img"
                            alt="Image"
                            class="rounded-0"
                          ></b-card-img>
                        </b-col>
                        <b-col md="6">
                          <b-card-body :title="article.title">
                            <b-card-text>
                              <p class="font-weight-light">
                                <i>
                                  {{ article.description }}
                                </i>
                              </p>
                            </b-card-text>
                            <b-col class="">
                              <p class="font-weight-light">
                                <b-icon class="mr-2" icon="clock"></b-icon>
                                <small>
                                  {{ article.date }}
                                </small>
                              </p>
                            </b-col>
                          </b-card-body>
                        </b-col>
                      </b-row>
                    </b-card>
                  </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </b-col>
        </b-row>
        <!-- SECTION CONTACT FORM  -->
        <b-row>
          <b-col cols="12">
            <contactform></contactform>
          </b-col>
        </b-row>
        <!-- SECTION FOOTER  -->
        <b-row>
          <b-col class="m-0 p-0">
            <footerComponent></footerComponent>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import timeline from "@/components/Timeline.vue";
import contactform from "@/components/ContactForm.vue";
import footerComponent from "@/components/Footer.vue";
import sidebarcontent from "@/components/SidebarContent.vue";

export default {
  name: "Home",
  components: { timeline, contactform, footerComponent, sidebarcontent },
  data() {
    return {
      isMobile: false,
      swiperInstanceArticles: null,
      carouselWomenIndex1: 0,
      carouselWomenIndex2: 0,
      // showSidebar: true,
      playingVideo: false,

      women: [
        {
          title: "Sor Juana Inés de la Cruz",
          quote: `“Todos aquellos que se eligen por algún medio para algún fin, se tienen por de menor aprecio que el fin a que se dirigen”`,
          img:
            "background-image: linear-gradient(to bottom,rgba(255, 255, 255, 0),#151515e0 ), url('https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/mujeres%2Fsor_juana.png?alt=media&token=93ea3f0d-40bd-4fcf-a591-1d33a49ba55e')",
          years: "Sor Juana Inés de la Cruz (poetisa) [1648 - 1695]",
        },
        {
          title: "Julieta Fierro",
          quote: `“Las mujeres también sabemos pensar. Muchas hacen cosas fantásticas”`,
          img:
            "background-image: linear-gradient(to bottom,rgba(255, 255, 255, 0),#151515e0 ), url('https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/mujeres%2Fjulieta.jpg?alt=media&token=f8210c5a-0a96-4f72-b32b-b505213aaa71')",
          years: "Julieta Fierro (astrónoma) [1948]",
        },
        {
          title: "María Félix",
          quote: `“Una mujer original no es aquella que no imita a nadie, sino aquella a la que nadie puede imitar”`,
          img:
            "background-image: linear-gradient(to bottom,rgba(255, 255, 255, 0),#151515e0 ), url('https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/mujeres%2Fmaria_felix.jpg?alt=media&token=6543eb9f-2d98-40b0-b41a-07cba9fb203c')",
          years: "María Félix (actriz) [1914 - 2002]",
        },
        {
          title: "Yalitza Aparicio",
          quote: `“Creo que la discriminación nos ha llevado a perder muchas cosas como mujeres indígenas: nuestra identidad”`,
          img:
            "background-image: linear-gradient(to bottom,rgba(255, 255, 255, 0),#151515e0 ), url('https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/mujeres%2Fyalitza2.jpg?alt=media&token=a316f9cc-0ae0-431c-a7a2-5c7d69f671cf')",
          years: "Yalitza Aparicio (actriz) [1993] - ",
        },
      ],
      women2: [
        {
          title: "Gabriela Ruiz Lugo",
          quote: `“Ves pocas mujeres en el ámbito gastronómico, pero la primera vez que un humano recibe un alimento es de una mujer”
`,
          img:
            "background-image: linear-gradient(to bottom,rgba(255, 255, 255, 0),#151515e0 ), url('https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/mujeres%2FGR.jpg?alt=media&token=0f3f5b76-ec8e-4fb6-aa10-8ef617c66c3b')",
          years: "Gabriela Ruiz Lugo (chef) [1987]",
        },
        {
          title: "Alondra de la Parra",
          quote: `“Si quieres que tu orquesta te ame… dedícate a otra cosa. A veces el lider debe sacrificar su popularidad en pos de una decisión ejecutiva”`,
          img:
            "background-image: linear-gradient(to bottom,rgba(255, 255, 255, 0),#151515e0 ), url('https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/mujeres%2FAP.jfif?alt=media&token=a10d0c36-b651-4e14-933d-4b3909d83c20')",
          years: "Alondra de la Parra (directora de orquesta) [1980] - ",
        },
        {
          title: "Elvia Carrillo Puerto",
          quote: `“La mujer en general y muy especialmente la obrera y campesina, significa uno de los factores más importantes de la población mexicana, y su participación en la lucha social reviste un carácter trascendental en la obra de emancipación”`,
          img:
            "background-image: linear-gradient(to bottom,rgba(255, 255, 255, 0),#151515e0 ), url('https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/mujeres%2Felvia.jpg?alt=media&token=fafc045d-2777-47a4-8fc7-bc8fca643f48')",
          years: "Elvia Carrillo Puerto (sufragista) [1881] - ",
        },
        {
          title: `“Adelita” `,
          quote: `“Sin ellas los soldados no hubieran comido ni dormido ni peleado”`,
          img:
            "background-image: linear-gradient(to bottom,rgba(255, 255, 255, 0),#151515e0 ), url('https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/mujeres%2Fadelita.jpg?alt=media&token=b0bc93d4-540f-4493-8072-029683b5ab3e')",
          years: `“Adelita” ( Katya Maldonado sobre 'La Adelita' )`,
        },
        // {
        //   title: "Elisa Carrillo",
        //   quote: "Some quote that Ines said",
        //   img:
        //     "background-image: linear-gradient(to bottom,rgba(255, 255, 255, 0),#151515e0 ), url('https://firebasestorage.googleapis.com/v0/b/gpt-mx.appspot.com/o/mujeres%2Felisa-carrillo.jpg?alt=media&token=b43f9f6a-55ba-4fd6-b56c-f91026cde847')",
        // },
      ],
      articles: [
        {
          title: "Titulo del primer articulo",
          description:
            "Breve descripcion del articulo para hacer preview del tema...",
          date: "06 de enero 2020",
          cover_img: "https://source.unsplash.com/random/400x400",
          article_id: 0,
        },
        {
          title: "Titulo del primer articulo 1",
          description:
            "Breve descripcion del articulo para hacer preview del tema...",
          date: "06 de enero 2020",
          cover_img: "https://source.unsplash.com/user/erondu/400x400",
          article_id: 1,
        },
        {
          title: "Titulo del primer articulo 2",
          description:
            "Breve descripcion del articulo para hacer preview del tema...",
          date: "06 de enero 2020",
          cover_img: "https://source.unsplash.com/collection/190727/400x400",
          article_id: 2,
        },
        {
          title: "Titulo del primer articulo 3",
          description:
            "Breve descripcion del articulo para hacer preview del tema...",
          date: "06 de enero 2020",
          cover_img: "https://source.unsplash.com/collection/190727/400x400",
          article_id: 3,
        },
        {
          title: "Titulo del primer articulo 4",
          description:
            "Breve descripcion del articulo para hacer preview del tema...",
          date: "06 de enero 2020",
          cover_img: "https://source.unsplash.com/collection/190727/400x400",
          article_id: 4,
        },
      ],
    };
  },

  created() {
    console.log(window.location.href);
    if (window.location.href == "http://girlpowertalkmexico.com/#/apply") {
      this.$router.push("Apply");
    }
    // window.addEventListener("scroll", this.handleScroll);
    this.isMobile = window.screen.width <= 540 ? true : false;
  },
  mounted() {
    // if (this.isMobile) {
    this.$refs.landingVideo.play();
    this.playingVideo = !this.playingVideo;
    // }
    var swiper = new Swiper(".swiper-container", {
      pagination: {
        el: ".swiper-pagination",
        dynamicBullets: true,
      },
      pagination: ".swiper-pagination",
    });
    this.swiperInstanceArticles = this.$refs.articlesRef;
  },

  methods: {
    playPauseVideo() {
      this.playingVideo === true
        ? this.$refs.landingVideo.pause()
        : this.$refs.landingVideo.play();
      this.showSidebar = !this.showSidebar;
      this.playingVideo = !this.playingVideo;
    },
    controlWomenCarousel1(slide) {
      this.carouselWomenIndex1 = slide;
    },
    controlWomenCarousel2(slide) {
      this.carouselWomenIndex2 = slide;
    },
  },
};
</script>

<style scoped>
.contact-form-bg {
  background-image: linear-gradient(to left, #151515, #e42e2e8e),
    url("https://source.unsplash.com/collection/190727/1600x900");
  background-size: cover;
  background-position: center;
}
.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-container:hover {
  cursor: pointer;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.wrapper {
  position: absolute;
  width: 100%;
}

.gradient {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0),
    #151515e7
  );
  width: 100%;
  height: calc(100vh - 80px);
  min-height: 100vh;
  object-fit: cover;
  position: relative;
  z-index: 2;
}

video {
  position: absolute;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  min-height: 100%;
  object-fit: cover;
}

.navigation-style {
  transition: transform ease-in-out 0.5s;
  transform: translate(0px);
}

.navigation-style:hover {
  color: rgba(255, 0, 0, 0.781) !important;
  transform: translate(10px);
}

#women-section-1 {
  height: 35em;
}

.women-section-1 {
  background-size: cover;
  background-position: center;
}

.card-img-style {
  height: 25em;
  width: 18em;
  background-size: cover;
  /* border-radius: 1.5em; */
}
/* NOTE Timeline-css  */

.card {
  border: none !important;
}
.custom-link {
  text-decoration: none;
}
.custom-link:hover {
  color: #fff !important;
}
</style>

<template>
  <b-container id="contacto" class="custom-height mb-4" fluid>
    <b-row>
      <b-col>
        <div class="background d-flex flex-row justify-content-end">
          <div class="container-2">
            <div class="screen">
              <div class="screen-body">
                <div class="screen-body-item left">
                  <div class="app-title">
                    <span class="mb-2 text-white text-center"
                      >¿Tienes una historia que contar?</span
                    >
                    <span class="text-danger my-2">Escríbenos</span>
                  </div>
                  <div class="app-contact">
                    <img
                      style="height:3em"
                      src="/Media/Icons/gpt-logo.png"
                      alt=""
                    />
                  </div>
                </div>
                <div class="screen-body-item">
                  <div class="app-form">
                    <div class="app-form-group">
                      <input
                        v-model="form.name"
                        @change="validForm"
                        class="app-form-control"
                        placeholder="NOMBRE"
                      />
                    </div>
                    <div class="app-form-group">
                      <input
                        v-model="form.email"
                        @change="validForm"
                        class="app-form-control"
                        placeholder="EMAIL"
                      />
                    </div>
                    <div class="app-form-group message">
                      <textarea
                        v-model="form.message"
                        @change="validForm"
                        type="textarea"
                        class="app-form-control"
                        placeholder="MENSAJE"
                      ></textarea>
                    </div>
                    <div class="app-form-group buttons">
                      <b-button
                        ref="sendButton"
                        @click="sendEmail"
                        :disabled="hideButton"
                        class=""
                        :class="{ 'app-form-button': !hideButton }"
                      >
                        ENVIAR
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "contactForm",
  data() {
    return {
      hideButton: true,
      text: null,
      form: {
        email: "",
        name: "",
        message: "",
      },
      show: true,
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },

    validForm() {
      if (
        this.form.name != "" &&
        this.form.email != "" &&
        this.form.message != ""
      ) {
        this.hideButton = false;
      } else {
        this.hideButton = true;
      }
    },

    // email method
    sendEmail() {
      Email.send({
        Host: "smtp.elasticemail.com",
        Username: "yaquedonoreply@gmail.com",
        Password: "0EB92F70C77E23F68F6559457484B92D3384",
        To: "daniel.drnts@gmail.com",
        From: "yaquedonoreply@gmail.com",
        Subject: `Mensaje de contacto: ${this.form.name}`,
        Body: `
        <div>
            <p style="background-color: #e8e925" >
              Nombre: ${this.form.name}, 
            </p>
          </div> <br>
          Email: ${this.form.email}, <br>
          Mensaje: ${this.form.message} 
        `,
      })
        .then((message) => {
          this.$refs.sendButton.innerHTML = "¡Gracias!";
        })
        .catch((err) => {
          alert(`Ha ocurrido un error: ${err}`);
          this.$refs.sendButton.innerHTML =
            "Ocurrió un error, intenta de nuevo";
        });
    },
  },
};
</script>

<style scoped>
body {
  background: linear-gradient(to right, #ea1d6f 0%, #eb466b 100%);
  font-size: 12px;
}

body,
button,
input {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  letter-spacing: 1.4px;
}
.background {
  display: flex;
  min-height: 50vh;
}
/* .container-2 {
  flex: 0 1 700px;
  margin: auto;
  padding: 10px;
} */

.custom-height {
  min-height: 23em;
}

.screen {
  position: relative;
  background: #1f1f1f;
  /* background: #3e3e3e; */
  border-radius: 15px;
}

.screen:hover {
  /* cursor: pointer; */
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.315);
  transition: box-shadow 0.3s ease-in-out;
}

.screen:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  bottom: 0;
  border-radius: 15px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  z-index: -1;
}

.screen-header {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background: #4d4d4f;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.screen-header-left {
  margin-right: auto;
}

.screen-header-button {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 3px;
  border-radius: 8px;
  background: white;
}

.screen-header-button.close {
  background: #ed1c6f;
}

.screen-header-button.maximize {
  background: #e8e925;
}

.screen-header-button.minimize {
  background: #74c54f;
}

.screen-header-right {
  display: flex;
}

.screen-header-ellipsis {
  width: 3px;
  height: 3px;
  margin-left: 2px;
  border-radius: 8px;
  background: #999;
}

.screen-body {
  display: flex;
}

.screen-body-item {
  flex: 1;
  padding: 50px;
}

.screen-body-item.left {
  display: flex;
  flex-direction: column;
}

.app-title {
  display: flex;
  flex-direction: column;
  position: relative;
  color: #f10707;
  font-size: 26px;
}

.app-title:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 25px;
  height: 4px;
  background: #ff0000;
}

.app-contact {
  margin-top: auto;
  font-size: 8px;
  color: #888;
}

.app-form-group {
  margin-bottom: 15px;
}

.app-form-group.message {
  margin-top: 40px;
}

.app-form-group.buttons {
  margin-bottom: 0;
  text-align: right;
}

.app-form-control {
  width: 100%;
  padding: 10px 0;
  background: none;
  border: none;
  border-bottom: 1px solid #666;
  color: #ddd;
  font-size: 14px;
  text-transform: uppercase;
  outline: none;
  transition: border-color 0.2s;
}

.app-form-control::placeholder {
  color: #666 !important;
}

.app-form-control:focus {
  border-bottom-color: #ddd !important;
}

.app-form-button {
  background: none;
  border: none;
  color: #ff0000;
  font-size: 14px;
  cursor: pointer;
  outline: none;
}

.app-form-button:hover {
  color: #b90808;
}

textarea:focus {
  color: #666 !important;
}

.dribbble {
  width: 20px;
  height: 20px;
  margin: 0 5px;
}

@media screen and (max-width: 520px) {
  .screen-body {
    flex-direction: column;
  }

  .screen-body-item.left {
    margin-bottom: 30px;
  }

  .app-title {
    flex-direction: row;
  }

  .app-title span {
    margin-right: 0px;
  }

  .app-title:after {
    display: none;
  }
  .background {
    display: flex;
    min-height: 36em;
  }
}

@media screen and (max-width: 600px) {
  .screen-body {
    padding: 40px;
  }

  .app-title {
    flex-direction: column;
  }

  .screen-body-item {
    padding: 0;
  }
}
</style>

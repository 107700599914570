<template>
  <b-container fluid>
    <b-row class="bg-danger">
      <b-col>
        <b-sidebar
          width="auto"
          style="z-index: 9900; background-color: #ffffff"
          :visible="!isMobile"
          id="sidebar-backdrop"
          @change="sidebarStats"
          ref="mysidebar"
          body-class="customClass"
        >
          <template v-slot:footer="{}">
            <div class="col-12 d-block ">
              <ul class="d-flex  justify-content-center p-0">
                <li class="nav-link">
                  <b-icon
                    class="sidebar-icon"
                    icon="instagram"
                    variant="danger"
                  ></b-icon>
                </li>
                <li class="nav-link">
                  <b-icon
                    class="sidebar-icon"
                    icon="facebook"
                    variant="danger"
                  ></b-icon>
                </li>
                <li class="nav-link">
                  <b-icon
                    class="sidebar-icon"
                    icon="twitter"
                    variant="danger"
                  ></b-icon>
                </li>
                <li class="nav-link">
                  <b-icon
                    class="sidebar-icon"
                    icon="linkedin"
                    variant="danger"
                  ></b-icon>
                </li>
              </ul>
            </div>
          </template>
          <template v-slot:header="{}">
            <b-container fluid>
              <b-row class="d-flex flex-column">
                <b-col v-if="isMobile" class="m-0 p-0 mb-2">
                  <div class="col-12 d-flex flex-row-reverse ">
                    <b-icon
                      variant="dark"
                      class="h1"
                      style="margin:0"
                      v-b-toggle.sidebar-backdrop
                      icon="x"
                    ></b-icon>
                  </div>
                </b-col>
                <b-col>
                  <div>
                    <router-link
                      class="navbar-brand brand-margin text-dark "
                      to="/"
                    >
                      GIRL
                      <span class="font-weight-bold">POWER</span>
                      TALK
                      <br />
                      <span class="font-weight-bold text-danger">MÉXICO</span>
                    </router-link>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </template>
          <div style="overflow-y: hidden" class="d-flex flex-column h-100 ">
            <div class="col-12 d-flex flex-column justify-content-center">
              <div>
                <ul class="navbar-nav d-flex flex-column ml-1">
                  <li class="nav-item navigation-style ">
                    <router-link
                      to="/"
                      class="nav-link font-weight-bold text-dark"
                    >
                      <p
                        style="border-radius: 2px"
                        class="setanimation py-1 px-2"
                      >
                        <span
                          :class="{
                            'text-danger': this.$route.name == 'Home',
                          }"
                        >
                          Conoce más
                        </span>
                      </p>
                    </router-link>
                  </li>
                  <li class="nav-item navigation-style ">
                    <router-link
                      to="/apply"
                      class="nav-link font-weight-bold text-dark"
                    >
                      <p
                        style="border-radius: 2px"
                        class="setanimation py-1 px-2"
                      >
                        <span
                          :class="{
                            'text-danger': this.$route.name == 'Apply',
                          }"
                        >
                          Aplica ahora
                        </span>
                      </p>
                    </router-link>
                  </li>

                  <li class="nav-item navigation-style ">
                    <router-link
                      to="/gallery"
                      class="nav-link font-weight-bold text-dark"
                    >
                      <p
                        style="border-radius: 2px;"
                        class="setanimation py-1 px-2"
                      >
                        <span
                          :class="{
                            'text-danger': this.$route.name == 'Gallery',
                          }"
                        >
                          Galería</span
                        >
                      </p>
                    </router-link>
                  </li>
                  <li class="nav-item navigation-style ">
                    <a
                      href="#contacto"
                      class="nav-link font-weight-bold text-dark "
                    >
                      <p
                        style="border-radius: 2px;"
                        class="setanimation py-1 px-2"
                      >
                        <span> Contacto</span>
                      </p>
                    </a>
                  </li>
                  <li class="nav-item navigation-style ">
                    <router-link
                      to="/articles"
                      class="nav-link font-weight-bold text-dark"
                      ><p
                        style="border-radius: 2px;"
                        class="setanimation py-1 px-2"
                      >
                        <span
                          :class="{
                            'text-danger': this.$route.name == 'Articles',
                          }"
                          >Artículos</span
                        >
                      </p></router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </b-sidebar>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "sidebarcontent",
  data() {
    return {
      isMobile: false,
      showSidebar: true,
      widthSidebar: "auto",
    };
  },

  created() {
    this.isMobile = window.screen.width <= 540 ? true : false;

    if (!this.isMobile) {
      // window.addEventListener("scroll", this.handleScroll);
    } else {
      this.showSidebar = false;
    }
  },

  destroyed() {
    // window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    sidebarStats() {
      if (!this.isMobile) {
        console.log("heheh");
        this.$refs.mysidebar.localShow = true;
      }
    },
    // handleScroll(event) {
    //   if (window.scrollY > 300) {
    //     if (this.showSidebar == true) {
    //       this.showSidebar = false;
    //     }
    //   }
    //   if (window.scrollY < 300) {
    //     if (this.showSidebar == false) {
    //       this.showSidebar = true;
    //     }
    //   }
    // },
  },
};
</script>

<style scoped>
a:hover {
  color: rgb(235, 38, 38) !important;
}
.icons-style {
  min-height: 1em;
  height: 1em;
}
li:hover {
  cursor: pointer;
}
/* #sidebar-backdrop {
  width: none !important;
} */
.sidebar-icon:hover {
  transition: color 0.5s ease-in-out;
  color: #151515 !important;
}

.setanimation {
  color: #151515;
  width: 100%;
}
.setanimation:hover {
  color: #c21616;
}
/* .setanimation:hover {
  color: azure;
  width: 130%;
  background-color: aqua;
  transition: width 1s ease-in-out;
} */

/* Animation for menu bullets */
@keyframes fillRed {
  from {
    width: 0%;
  }
  to {
    width: 30%;
  }
}

@media screen and (min-width: 1910px) {
  /* .b-sidebar {
    width: none !important;
    background-color: #c21616 !important;
  }
  .setanimation {
    color: #1cc216 !important;
  } */
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { title: "Girl Power Talk México" },
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
  {
    path: "/gallery",
    name: "Gallery",
    component: () => import("../views/Gallery.vue"),
    meta: { title: "Conoce más" },
  },
  {
    path: "/apply",
    name: "Apply",
    component: () => import("../views/Apply.vue"),
    meta: { title: "Aplica ahora" },
  },
  {
    path: "/articles",
    name: "Articles",
    component: () => import("../views/Articles.vue"),
    meta: { title: "Nuestros artículos" },
  },
];

// Titulos de tab en las distintas paginas
const DEFAULT_TITLE = "Girl Power Talk  México";

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
